@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("./assets/fonts/AvenirLT-Book.ttf") format("trutype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("./assets/fonts/AvenirLT-Light.ttf") format("trutype");
    font-weight: light;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("./assets/fonts/AvenirLT-Medium.ttf") format("trutype");
    font-weight: medium;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("./assets/fonts/Avenir-Bold.ttf") format("trutype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("./assets/fonts/AvenirLT-Heavy.ttf") format("trutype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("./assets/fonts/AvenirLT-Black.ttf") format("trutype");
    font-weight: 900;
    font-style: normal;
}

iframe .paypal-button-number-1 {
    display: none !important;
}

html {
    overscroll-behavior-y: none;
}

body {
    overscroll-behavior-y: contain;
}

.react-responsive-modal-modal {
    border-radius: 8px;
}

.react-responsive-modal-closeButton {
    right: auto;
    left: 14px;
    svg {
        fill: #B2C0C3 !important;
    }
}

/*.pac-container:first-child {*/
/*  box-shadow: none !important;*/
/*}*/

