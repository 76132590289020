@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("AvenirLT-Book.ttf") format("trutype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("AvenirLT-Light.ttf") format("trutype");
    font-weight: light;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("AvenirLT-Medium.ttf") format("trutype");
    font-weight: medium;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("Avenir-Bold.ttf") format("trutype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("AvenirLT-Black.ttf") format("trutype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir") url("AvenirLT-Heavy.ttf") format("trutype");
    font-weight: 900;
    font-style: normal;
}